<template>
    <b-modal
      v-model="data.showModal"
      button-size="sm"
      modal-class="invoice-modal"
      title-class="w-100"
      footer-class="w-100"
      no-fade
      @show="onShow"
      size="lg"
      :no-close-on-backdrop="true"
      scrollable
    >
      <!-- Modal header -->
      <div slot="modal-title">
        <h1 class="flex flex-column gap-2 w-50">
          <div class="text-2xl font-bold">Invoice</div>
          <div v-if="invoice" class="text-xl">{{ invoice.invoice_code }}</div>
        </h1>
      </div>

      <!-- Modal content -->
      <div v-if="invoice" class="flex flex-column gap-4">
        <table class="table-invoice">
          <tbody>
            <tr>
              <td class="row-fit">Type</td>
              <td>
                <button-select
                  v-model="invoice.invoice_type"
                  :options="options.invoice_type"
                  size="sm"
                  class="w-50"
                />
              </td>
            </tr>

            <tr>
              <td class="row-fit">Supplier</td>
              <td>
                <v-select
                  v-model="invoice.partner_id"
                  :options="suppliers"
                  size="sm"
                  label="text"
                  :reduce="option => option.value"
                />
              </td>
            </tr>

            <tr>
              <td class="row-fit">Date</td>
              <td>
                <date-picker
                  class="date-picker w-50"
                  v-model="invoice.issue_date"
                  format="DD.MM.YYYY"
                  valueType="YYYY-MM-DD"
                  :clearable="false"
                  :lang="datepickerLocale"
                />
              </td>
            </tr>

            <tr>
              <td class="row-fit">Invoice</td>
              <td>
                <b-form-input
                  v-model="invoice.invoice_code"
                  autocomplete="off"
                  :class="{ 'border-1 border-red-500': !invoiceValidation.invoice_code }"
                  size="sm"
                  trim
                ></b-form-input>
              </td>
            </tr>

            <tr>
              <td class="row-fit">Amount</td>
              <td>
                <b-input-group>
                  <b-form-input
                    v-model="invoice.price_brutto"
                    autocomplete="off"
                    :class="{ 'border-1 border-red-500': !invoiceValidation.price_brutto }"
                    size="sm"
                  ></b-form-input>

                  <template #append>
                    <b-form-select
                      v-model="invoice.currency_id"
                      :options="options.currencies"
                      size="sm"
                    ></b-form-select>
                  </template>
                </b-input-group>

              </td>
            </tr>

            <tr>
              <td>Notes</td>
              <td>
                <b-form-textarea
                  v-model="invoice.invoice_note"
                  rows="6"
                  max-rows="6"
                  trim
                ></b-form-textarea>
              </td>
            </tr>

            <tr>
              <td>Files</td>
              <td>
                <table class="table table-google table-invoices">
                  <thead>
                    <tr>
                      <th>File</th>
                      <th class="row-fit">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="invoice.files.length === 0">
                      <td colspan="2">No files</td>
                    </tr>
                    <tr v-for="file in invoice.files" :key="file.id">
                      <td>
                        <b-link
                          :href="file.link"
                          target="_blank"
                          class="custom-link"
                        >
                          {{ file.filename }}
                        </b-link>
                      </td>
                      <td class="row-fit">
                        <div>
                          <b-btn
                            variant="danger"
                            size="sm"
                            @click="deleteFile(file)"
                          >
                            Delete
                          </b-btn>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <b-form-file
                  ref="upload"
                  v-model="upload"
                  required
                  :state="Boolean(upload)"
                  placeholder="Choose a file.."
                  label-size="sm"
                  :disabled="isLoading"
                  @change.native="uploadFile"
                  no-drop
                  class="upload"
                ></b-form-file>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else-if="isLoading">
        Loading..
      </div>

      <!-- Modal footer -->
      <div slot="modal-footer" class="w-100 flex justify-between gap-4 items-center">
        <div>
          <b-btn
            variant="primary"
            size="sm"
            @click="saveInvoice()"
            :disabled="!canSave"
          >
            Save
          </b-btn>
        </div>

        <div>
          <b-btn
            variant="danger"
            size="sm"
            @click="deleteInvoice()"
          >
            Delete
          </b-btn>
        </div>
      </div>
    </b-modal>
  </template>

<script>
import {
  datepickerLocale,
  numberToString,
  stringToNumber,
} from '@/helpers';

const ButtonSelect = () => import('@/components/global/ButtonSelect.vue');

export default {
  name: 'InvoiceModal',
  components: {
    ButtonSelect,
  },
  props: {
    data: Object,
    suppliers: Array,
  },
  computed: {
    invoiceData() {
      const invoiceData = { ...this.invoice };
      invoiceData.price_brutto = stringToNumber(invoiceData.price_brutto);
      return invoiceData;
    },
    invoiceValidation() {
      return {
        invoice_code: this.invoiceData.invoice_code.length > 0,
        price_brutto: !isNaN(this.invoiceData.price_brutto) && this.invoiceData.price_brutto !== 0,
      };
    },
    datepickerLocale() {
      return datepickerLocale;
    },
    isLoading() {
      return this.loadingCount > 0;
    },
    canSave() {
      if (!this.invoice) return false;
      if (this.isLoading) return false;
      return Object.values(this.invoiceValidation).every((val) => val === true);
    },
  },
  data() {
    return {
      loadingCount: 0,
      invoice: null,
      upload: null,
      options: {
        invoice_type: [
          { text: 'Invoice', value: 'invoice' },
          { text: 'Proforma', value: 'proforma' },
        ],
        currencies: this.$store.state.app.currencies.map((currency) => ({
          text: currency.currency_code,
          value: currency.id,
        })),
      },
    };
  },
  methods: {
    onShow() {
      this.fetchInvoice();
    },
    onClose() {
      this.data.showModal = false;
      this.$emit('close');
    },
    fetchInvoice() {
      this.loadingCount++;
      this.$http
        .get(`/invoice/${this.data.invoice.id}`)
        .then((res) => {
          this.invoice = res.body.invoice;
          this.invoice.price_brutto = numberToString(this.invoice.price_brutto);
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch invoice: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    saveInvoice() {
      this.loadingCount++;
      this.$http
        .put(`/invoice/${this.data.invoice.id}`)
        .send({ changes: this.invoiceData })
        .then(() => {
          this.$emit('change', this.data.invoice.id);
          this.onClose();
        })
        .catch((err) => {
          this.$toast.error(`Failed to update invoice: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    deleteInvoice() {
      if (!confirm(`Do you really wish to delete invoice '${this.data.invoice.invoice_code}'?`)) {
        return;
      }
      this.loadingCount++;
      this.$http
        .delete(`/invoice/${this.data.invoice.id}`)
        .then(() => {
          this.$emit('delete', this.data.invoice.id);
          this.onClose();
        })
        .catch((err) => {
          this.$toast.error(`Failed to delete invoice: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    uploadFile() {
      this.loadingCount++;
      const formData = new FormData();
      formData.append('upload', this.upload);
      formData.append('mtime', this.upload.lastModified / 1000);
      this.$http
        .post(`/invoice/${this.data.invoice.id}/files`)
        .send(formData)
        .then((res) => {
          this.invoice.files.push(res.body.invoiceFile);
          this.$emit('change', this.data.invoice.id);
        })
        .catch((err) => {
          this.$toast.error(`Failed to upload file: ${err.response.text}`);
        })
        .finally(() => {
          this.upload = null;
          this.loadingCount--;
        });
    },
    deleteFile(file) {
      if (!confirm(`Do you really wish to delete file '${file.filename}'?`)) {
        return;
      }
      this.loadingCount++;
      this.$http
        .delete(`/invoice/${this.data.invoice.id}/files/${file.id}`)
        .then(() => {
          this.invoice.files = this.invoice.files.filter((f) => f.id !== file.id);
          this.$emit('change', this.data.invoice.id);
        })
        .catch((err) => {
          this.$toast.error(`Failed to delete file: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  .table-invoice {
    tr {
      border-top: 5px solid transparent;
    }

    tr > td:first-child {
      padding-right: 10px;
    }
  }
</style>
